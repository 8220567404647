<template>
  <div class="flex flex-col lg:flex-row lg:flex-wrap w-full pb-4 mb-10">
    <email-not-confirmed-alert v-if="!investor.emailConfirmado" class="mt-2 md:mt-4" req="investidor" />
    <incomplete-profile-alert v-if="!investor.cadastroEstaCompleto" class="mt-2 md:mt-4" />
    <suitability-alert v-if="!investor.suitabilityEstaPreenchido" class="mt-2 md:mt-4" />
    <black-friday-alert v-if="false" class="mt-2 md:mt-4" />

    <div class="w-full lg:w-9/12 pt-2 lg:pt-4 pr-1">
      <resume :investor="investor" class="mb-2" />
      <investiment-round class="mb-2 lg:hidden" />
      <div class="flex flex-col md:flex-row">
        <shared :code="investor.codigoIndicacao" class="w-full md:w-2/3 mb-2 md:mr-2 lg:mr-0" />
      </div>
    </div>
    <div class="hidden lg:block lg:w-3/12 pt-4 pl-1">
      <investiment-round class="mb-2" />
    </div>
    <div class="w-full">
      <business />
    </div>
  </div>
</template>

<script>
import InvestimentRound from '@/components/Investor/Dashboard/InvestimentRound'
import Resume from '@/components/Investor/Dashboard/Resume'
import Shared from '@/components/Investor/Dashboard/Shared'
import Business from '@/components/Investor/Dashboard/Business'
import Dashboard from '@/services/Investor/Dashboard'

import EmailNotConfirmedAlert from '@/components/Alerts/EmailNotConfirmedAlert'
import SuitabilityAlert from '@/components/Investor/Alerts/SuitabilityAlert'
import IncompleteProfileAlert from '@/components/Investor/Alerts/IncompleteProfileAlert'
import BlackFridayAlert from '@/components/Investor/Alerts/BlackFridayAlert'

export default {
  name: 'dashboard-overview',
  components: { InvestimentRound, Resume, Shared, Business, EmailNotConfirmedAlert, SuitabilityAlert, IncompleteProfileAlert, BlackFridayAlert },
  data() {
    return {
      investor: {
        emailConfirmado: true,
        cadastroEstaCompleto: true,
        suitabilityEstaPreenchido: true
      }
    }
  },
  async created() {
    try {
      this.investor = (await Dashboard.getStatus()).data.data
    } catch (error) {
      console.error(error)
    }
  }
}
</script>
