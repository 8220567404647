<template>
  <div class="shared">
    <transition name="fade">
      <friends-modal v-if="showFriendsModal" @closeModal="showFriendsModal = false"/>
    </transition>
    <transition name="fade">
      <invite-friends-modal v-if="showInviteFriendsModal" @closeModal="showInviteFriendsModal = false"/>
    </transition>
    <div class="w-full lg:w-8/12">
      <p class="title">Convide amigos compartilhando seu link</p>
      <p class="subtitle">Para cada convite aceito você ganha R$ 20,00 no primeiro investimento, e seu amigo R$ 30,00</p>
      <div class="copy">
        <div class="copy_input">
          <input ref="text" @click="copy" :value="link" class="input" type="text" readonly :title="link">
          <i :class="isCopied ? 'text-primary' : 'text-gray-700'" class="fas fa-clone absolute right-0 mr-8 cursor-pointer hidden md:block" @click="copy"></i>
        </div>
        <v-button color="primary" class="my-2" size="md" @click="showFriendsModal = true">Ver convites aceitos</v-button>
      </div>
    </div>
    <div class="line_break">
      <span class="vertical_line"></span>
      <p class="line_text">ou</p>
      <span class="vertical_line"></span>
    </div>
    <div class="w-full lg:w-3/12 flex flex-col items-center justify-center">
      <p class="title">Compartilhe em</p>
      <div class="icons">
        <a :href="whatsAppLink" target="_blank" class="icon_content bg-primary">
          <img class="icon" src="@/assets/images/investor_panel/icon_wathsapp.svg" alt="">
        </a>
        <a :href="facebookLink" target="_blank" class="icon_content">
          <img src="@/assets/images/login/facebook.svg" alt="">
        </a>
        <div class="icon_content bg-red-700" @click="showInviteFriendsModal = true">
          <i class="fas fa-envelope text-white text-xl"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FriendsModal from '@/components/Investor/Modal/FriendsModal'
import InviteFriendsModal from '@/components/Investor/Modal/InviteFriendsModal'
import VButton from '@/components/Buttons/Button'

export default {
  name: 'shared',
  components: { VButton, FriendsModal, InviteFriendsModal },
  props: ['code'],
  data () {
    return {
      isCopied: false,
      showFriendsModal: false,
      showInviteFriendsModal: false
    }
  },
  computed: {
    link () {
      return `${process.env.VUE_APP_SITE_URL}?i=${this.code || ''}`
    },
    message () {
      return `Olá! Vamos ganhar dinheiro juntos? Use o link abaixo para ganhar R$30 ao efetivar o primeiro investimento na TuTu Digital ${this.link}`
    },
    whatsAppLink () {
      return `https://api.whatsapp.com/send?text=${this.message}`
    },
    facebookLink () {
      return `https://www.facebook.com/dialog/share?app_id=${process.env.VUE_APP_FACEBOOK_APPID}&display=page&href=${this.link}`
    }
  },
  methods: {
    copy () {
      try {
        this.$refs.text.select()
        if (document.execCommand('copy')) {
          this.isCopied = true
          this.$store.dispatch('notification', { type: 'success', message: 'Link copiado com sucesso!!' })
        }
      } catch (err) {
        this.isCopied = false
        this.$store.dispatch('notification', { type: 'error', message: 'Erro ao copiar link!!' })
        console.error(err)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.shared
  @apply flex flex-col items-center w-full h-full shadow bg-white rounded-lg px-2 pt-6 pb-3
  @screen md
    @apply px-4
  @screen lg
    @apply flex-row h-48

.flex_col
  @apply flex flex-col items-center justify-center

.title
  @apply text-gray-700 text-sm text-center font-bold mb-2
  @screen lg
    @apply text-left text-base

.subtitle
  @apply text-gray-800 text-xs opacity-50 text-center
  @screen lg
    @apply text-left

.copy
  @apply flex flex-col items-center w-full
  @screen lg
    @apply flex-row

.copy_input
  @apply relative flex items-center w-full mt-4
  @screen lg
    @apply w-10/12 mt-0

.line_break
  @apply w-full flex flex-row justify-center items-center mb-2
  @screen lg
    @apply flex-col w-1/12 pl-8 mb-0

.line_text
  @apply text-sm text-gray-700 w-1/12 text-center pb-1 mx-1
  @screen lg
    @apply w-full pb-0 mx-0

.vertical_line
  @apply h-px w-5/12 bg-gray-400
  @screen lg
    @apply h-10 w-px

.icons
  @apply flex

.icon_content
  @apply w-10 h-10 rounded-full flex justify-center items-center mr-2 shadow-md cursor-pointer select-none
  &:hover
    opacity: 0.9
  &:last-child
    @apply mr-0
  @screen lg
    @apply w-12 h-12

.icon
  @apply w-5 h-5 bg-no-repeat bg-center
  filter: brightness(0) invert(1)
  @screen lg
    @apply w-6 h-6

.input
  @apply rounded-full bg-gray-100 text-primary px-4 w-full h-10 cursor-pointer text-xs
  @screen lg
    @apply text-sm mr-6 pr-8
  &:focus
    @apply outline-none
</style>
