<template>
  <div class="main-content">
    <div class="flex flex-col items-center lg:flex-row lg:justify-between py-3">
      <div>
        <p class="title">Oportunidades de investimento</p>
        <div class="boxes">
          <p class="mb-2 font-semibold text-sm text-gray-700 md:w-full lg:w-auto lg:mb-0">Status: </p>
          <v-radio class="w-full md:w-1/2 lg:w-auto text-sm lg:ml-2" name="todos" v-model="status" value="todos"
            label="Todos"></v-radio>
          <v-radio class="w-full md:w-1/2 lg:w-auto text-sm lg:ml-2" name="abertas" v-model="status" value="abertas"
            label="Abertas"></v-radio>
          <v-radio class="w-full md:w-1/2 lg:w-auto text-sm lg:ml-2" name="encerradas" v-model="status" value="encerradas"
            label="Encerradas"></v-radio>
        </div>
        <div class="boxes">
          <p class="mb-2 font-semibold text-sm text-gray-700 md:w-full lg:w-auto lg:mb-0">Tipo de empresa: </p>
          <v-radio class="w-full md:w-1/2 lg:w-auto text-sm lg:ml-2" name="todas" v-model="filter" value="todas"
            label="Todas"></v-radio>
          <v-radio class="w-full md:w-1/2 lg:w-auto text-sm lg:ml-2" name="mpe" v-model="filter" value="mpe"
            label="Micro e Pequenas empresas"></v-radio>
          <v-radio class="w-full md:w-1/2 lg:w-auto text-sm lg:ml-2" name="startups" v-model="filter" value="startups"
            label="Startups"></v-radio>
          <v-radio class="w-full md:w-1/2 lg:w-auto text-sm lg:ml-2" name="nano" v-model="filter" value="nano"
            label="Nano Empreendedores"></v-radio>
        </div>
      </div>
      <div class="lg: mr-2">
        <div class="button button_grid" :class="view === 'grid' && 'button_active'" @click="view = 'grid'">
          <i class="fas fa-th-large"></i>
        </div>
        <div class="button button_list" :class="view === 'list' && 'button_active'" @click="view = 'list'">
          <i class="fas fa-list"></i>
        </div>
      </div>
    </div>
    <div v-if="view === 'list'" class="overflow-x-auto lg:overflow-x-hidden">
      <vuetable ref="vuetable" :fields="header" :api-mode="false" :data-manager="dataManager"
        :css="{ tableClass: 'business' }" :no-data-template="loading ? '' : 'Nenhum oportunidade encontrada'">
        <template v-slot:nomeEmpresa="props">
          <div class="flex items-center">
            <img class="mr-4 w-16 h-14 object-contain" :src="props.rowData.logotipo" />
            <div>
              <p class="text-sm font-bold text-left">{{ props.rowData.nomeEmpresa }}</p>
              <p class="text-xs text-left">{{ props.rowData.status }}</p>
            </div>
          </div>
        </template>
        <template v-slot:ratingStr="props">
          <div class="flex items-center justify-center w-40">
            <div class="line">
              <div class="relative flex flex-col items-end ml-2" :style="`width: ${props.rowData.ratingEmPorcentagem}%`">
                <img class="carret" src="@/assets/images/investor_panel/carret.png" alt="carret">
                <p class="risk">{{ props.rowData.ratingStr }}</p>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:prazo="props">
          <div class="flex items-center justify-center">
            <p>{{ props.rowData.prazo }} meses</p>
          </div>
        </template>
        <template v-slot:valorSerArrecadado="props">
          <div class="flex items-center justify-center">
            <p>{{ props.rowData.valorSerArrecadado | money }}</p>
          </div>
        </template>
        <template v-slot:tirBrutaAoAno="props">
          <div class="flex flex-col items-center">
            <p>{{ props.rowData.tirBrutaAoAno.toFixed(2) }}% a.a</p>
            <p class="text-xss">{{ props.rowData.tirBrutaAoMes.toFixed(2) }}% a.m</p>
          </div>
        </template>
        <template v-slot:porcentagemLevantada="props">
          <div class="flex items-center justify-center">
            <p>{{ props.rowData.porcentagemLevantada }}%</p>
          </div>
        </template>
        <template v-slot:action="props">
          <div class="flex items-center justify-center">
            <router-link :to="`/investidor/painel/oportunidade/${props.rowData.id}`" class="text-primary text-sm"
              href="">Ver mais</router-link>
          </div>
        </template>
      </vuetable>
    </div>
    <div v-else class="flex flex-col lg:flex-row lg:flex-wrap">
      <div class="w-full lg:w-1/3 p-1" v-for="bus of business" :key="`card_${bus.id}`">
        <div class="card">
          <div class="card_header">
            <img class="h-20 object-contain" :src="bus.logotipo" />
            <p class="text-sm font-bold">{{ bus.nomeEmpresa }}</p>
            <p class="text-xs">{{ bus.status }}</p>
          </div>
          <hr class="w-full h-px bg-black opacity-25 my-3">

          <div class="card_body">
            <div class="card_content">
              <p>Valor Solicitado</p>
              <p class="font-bold">{{ bus.valorSerArrecadado | money }}</p>
            </div>
            <div class="card_content">
              <p>Duração Esperada</p>
              <p class="font-bold">{{ bus.prazo }} meses</p>
            </div>
          </div>
          <hr class="w-full h-px bg-black opacity-25 my-3">

          <div class="card_body">
            <div class="card_content2">
              <p style="font-weight: bold;" class="text-center text-green">
                <span v-if="!bus.garantiaRecebiveis">Garantia de aval dos sócios</span>
                <span v-if="bus.garantiaRecebiveis">Garantia de recebíveis + garantia de aval dos sócios</span>
              </p>
            </div>
          </div>
          <hr class="w-full h-px bg-black opacity-25 my-3">
          <div class="card_body">
            <div class="card_content">
              <p>Risco de Crédito</p>
              <div class="line">
                <div class="relative flex flex-col items-end ml-2" :style="`width: ${bus.ratingEmPorcentagem}%`">
                  <img class="carret" src="@/assets/images/investor_panel/carret.png" alt="carret">
                  <p class="risk">{{ bus.ratingStr }}</p>
                </div>
              </div>
            </div>
            <div class="card_content">
              <p>Rentabilidade</p>
              <p class="font-bold">{{ bus.tirBrutaAoAno.toFixed(2) }}% a.a</p>
              <p class="text-xss">{{ bus.tirBrutaAoMes.toFixed(2) }}% a.m</p>
            </div>
          </div>
          <hr class="w-full h-px bg-black opacity-25 my-3">
          <div class="card_footer">
            <div class="card_content">
              <p>Total Levantado</p>
              <div class="relative flex flex-col w-full h-6 bg-gray-300 rounded-lg mt-1">
                <div class="absolute h-full bg-primary flex items-center justify-end rounded-l-lg"
                  :class="bus.porcentagemLevantada === 100 && 'rounded-r-lg'"
                  :style="`width:${bus.porcentagemLevantada}%`">
                  <p class="text-white text-xss mr-1">{{ bus.porcentagemLevantada }}%</p>
                </div>
              </div>
            </div>
            <v-button class="w-1/2 flex items-center justify-center" size="sm"
              :link="`/investidor/painel/oportunidade/${bus.id}`" margin="my-0">Ver mais</v-button>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-1/3 mx-auto p-4" v-if="business.length === 0 && !loading">
        <div class="card">
          <div class="flex justify-center items-center">
            <p class="text-sm font-bold">Nenhuma oportunidade encontrada</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="w-full flex flex-col justify-center items-center my-4">
      <span class="fa fa-spinner fa-spin text-2xl text-secondary"></span>
    </div>
    <div class="flex justify-end">
      <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"
        :css="paginationCss"></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import { VuetablePagination } from 'vuetable-2'
import VRadio from '@/components/Inputs/Radio'
import VButton from '@/components/Buttons/Button'

import Dashboard from '@/services/Investor/Dashboard'
import MoneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'investor-opportunities',
  components: { VRadio, VuetablePagination, VButton },
  mixins: [MoneyFilter],
  data() {
    return {
      view: 'grid',
      loading: true,
      filter: 'todas',
      status: 'todos',

      business: [],
      pagination: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0
      },
      header: [
        {
          name: '__slot:nomeEmpresa',
          title: 'Tomador'
        },
        {
          name: '__slot:prazo',
          title: 'Duração esperada'
        },
        {
          name: '__slot:valorSerArrecadado',
          title: 'Valor solicitado'
        },
        {
          name: '__slot:tirBrutaAoAno',
          title: 'Rentabilidade'
        },
        {
          name: '__slot:ratingStr',
          title: 'Risco'
        },
        {
          name: '__slot:porcentagemLevantada',
          title: 'Levantado'
        },
        {
          name: '__slot:action',
          title: ''
        }
      ],
      paginationCss: {
        wrapperClass: 'border rounded-lg flex m-2 text-secondary',
        activeClass: 'text-primary shadow-inner bg-gray-100 font-bold',
        disabledClass: 'text-gray-300 cursor-not-allowed',
        pageClass: 'pagination_page_class',
        linkClass: 'pagination_page_class',
        icons: {
          first: 'fas fa-angle-double-left',
          prev: 'fas fa-angle-left',
          next: 'fas fa-angle-right',
          last: 'fas fa-angle-double-right'
        }
      }
    }
  },
  methods: {
    rating(value) {
      switch (value.toUpperCase()) {
        case 'AA':
          return 16
        case 'A1':
          return 15
        case 'A2':
          return 14
        case 'A3':
          return 13
        case 'B1':
          return 12
        case 'B2':
          return 11
        case 'B3':
          return 10
        case 'B4':
          return 9
        case 'B5':
          return 8
        case 'B6':
          return 7
        case 'C1':
          return 6
        case 'C2':
          return 5
        case 'C3':
          return 4
        case 'D1':
          return 3
        case 'D2':
          return 2
        case 'D3':
          return 1
      }
    },
    onChangePage(page) {
      switch (true) {
        case page === 'next':
          return this.getOpportunities(this.pagination.current_page + 1)
        case page === 'prev':
          return this.getOpportunities(this.pagination.current_page - 1)
        default:
          return this.getOpportunities(page)
      }
    },
    dataManager(sortOrder, pagination) {
      if (this.business.length < 1) return
      return {
        pagination: this.pagination,
        data: this.business
      }
    },
    async getOpportunities(page) {
      if (page === this.pagination.current_page || page < 1 || page > this.pagination.last_page) return
      try {
        this.loading = true
        this.$refs.vuetable && this.$refs.vuetable.resetData()
        this.business = []
        let response = []
        switch (this.status) {
          case 'abertas':
            response = (await Dashboard.getOpportunitiesOpen(page, this.filter)).data.data
            break
          case 'encerradas':
            response = (await Dashboard.getOpportunitiesClosed(page, this.filter)).data.data
            break
          default:
            response = (await Dashboard.getOpportunities(page, this.filter)).data.data
        }
        this.business = response.itens
        this.pagination = {
          current_page: response.paginaAtual,
          last_page: response.paginas,
          total: response.total,
          per_page: response.itens.length
        }
        this.$refs.vuetable && this.$refs.vuetable.reload()
        this.$refs.pagination.setPaginationData(this.pagination)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    status() {
      this.getOpportunities()
    },
    filter() {
      this.getOpportunities()
    }
  },
  async mounted() {
    await this.getOpportunities()
  }
}
</script>

<style lang="sass" scoped>
.main-content
  @apply w-full h-full bg-white border shadow rounded-lg

.title
  @apply text-gray-700 text-sm text-center font-bold mb-2 ml-4
  @screen lg
    @apply text-left text-base

.boxes
  @apply flex items-start pl-4 pt-2 pb-4 text-xs flex-col
  @screen md
    @apply flex-row items-center flex-wrap
  @screen lg
    @apply flex-no-wrap pb-0

.input_label
  @apply text-xs text-gray-600 cursor-pointer

.line
  @apply h-2 w-full mt-3 mb-5
  background: linear-gradient(to right,#ec4c49 0,#f9a021 50%,#73c04f 100%)

.risk
  @apply absolute text-black uppercase text-xss font-semibold z-10 select-none
  top: 100%

.carret
  @apply select-none

.button
  @apply inline bg-gray-200 py-1 px-2 text-gray-700 cursor-pointer
  &_grid
    @apply rounded-l-lg
  &_list
    @apply rounded-r-lg
  &_active
    @apply text-white bg-primary

.card
  @apply w-full bg-white border shadow rounded-lg p-2 text-gray-600
  @screen lg
    @apply px-4
  &_header
    @apply flex flex-col items-center justify-center
    @screen lg
      @apply
  &_body
    @apply flex
    @screen lg
      @apply
  &_content
    @apply w-1/2 flex flex-col items-center justify-center text-sm
    @screen lg
      @apply
  &_content2
    @apply w-full flex flex-col items-center justify-center text-sm
    @screen lg
      @apply
  &_footer
    @apply flex
    @screen lg
      @apply

</style>

<style lang="sass">
.business
  @apply w-full text-sm
  thead tr th
    @apply p-4 text-xs text-gray-600
    background: #ECECEC
  tr
    @apply border-b border-gray-300
  td
    @apply p-4 text-gray-600 text-center whitespace-normal
    &:first-child
      @apply w-104 pl-2

.pagination_page_class
  @apply h-8 w-8 border-l flex justify-center items-center cursor-pointer
  &:first-of-type
    @apply border-l-0 rounded-l-lg
  &:last-of-type
    @apply rounded-r-lg
  &:hover
    @apply bg-gray-100
    &.cursor-not-allowed
      @apply bg-transparent

</style>
