<template>
  <div class="cards">
    <div class="card">
      <div class="w-full lg:w-48">
        <div class="line_vertical"></div>
        <div class="ml-4">
          <p class="card_title">Total Investido</p>
          <p class="card_value">{{investor.totalInvestido || 0 | money}}</p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="w-full lg:w-48">
        <div class="line_vertical"></div>
        <div class="ml-4">
          <p class="card_title">Total a Receber</p>
          <p class="card_value">{{investor.totalReceber || 0 | money}}</p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="w-full lg:w-48">
        <div class="line_vertical"></div>
        <div class="ml-4">
          <p class="card_title">Aportes Pendentes</p>
          <p class="card_value">{{investor.valorAportesPendentes || 0 | money}}</p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="w-full lg:w-48">
        <div class="line_vertical"></div>
        <div class="ml-4">
          <p class="card_title">Total Recebido</p>
          <p class="card_value">{{investor.totalRecebido || 0 | money}}</p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="w-full lg:w-48">
        <div class="line_vertical"></div>
        <div class="ml-4">
          <p class="card_title">Último Recebimento</p>
          <p class="card_value">{{investor.ultimoRecebimento || 0 | money}}</p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="w-full lg:w-48">
        <div class="line_vertical"></div>
        <div class="ml-4">
          <p class="card_title">Valor em Trânsito</p>
          <p class="card_value">{{investor.valorEmTransito || 0 | money}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'resume',
  mixins: [MoneyFilter],
  props: ['investor']
}
</script>

<style lang="sass" scoped>
.cards
  @apply w-full h-full bg-white flex flex-wrap border shadow rounded-lg
  @screen lg
    @apply items-center h-56

.card
  @apply relative flex justify-center mt-5 h-20 w-1/2 pl-4
  @screen md
    @apply w-1/3 justify-center pl-4 mt-2
  @screen lg
    @apply pl-0

.line_vertical
  @apply w-1 absolute bg-primary
  height: 80%
  @screen lg
    height: 90%

.card_title
  @apply text-sm leading-none text-gray-700 mt-3
  @screen lg
    @apply text-base mt-2

.card_value
  @apply text-secondary font-semibold
  @screen lg
    @apply text-2xl

.card_text
  @apply text-xs text-gray-700 leading-normal
</style>
