<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <p class="title">Convidar amigos</p>
        <div class="flex">
          <v-input class="w-full mr-4" name="email" label="E-mail" v-model="email" :error="errors.first('email')" v-validate="{ required: true, email: true, excluded: emails }"></v-input>
          <i @click="addEmail" class="fas fa-plus my-auto p-3 bg-primary text-white rounded-full"></i>
        </div>
        <div class="w-full overflow-x-auto lg:overflow-x-hidden">
          <table>
            <thead>
              <th>
                <td>
                  E-mails
                </td>
              </th>
            </thead>
            <tbody>
              <tr v-for="(email, index) of emails" :key="index">
                <td class="flex justify-between items-center px-4">
                  {{email}}
                  <i class="fas fa-trash text-red-600 cursor-pointer" @click="emails.splice(index, 1)"></i>
                </td>
              </tr>
              <tr v-if="!emails.length" class="text-center text-sm">
                <td class="py-2">
                  Não há e-mails na lista ainda. Adicione!
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex items-center justify-center">
            <v-button @click="submit" :disabled="!emails.length" :loading="loading">Convidar</v-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VButton from '@/components/Buttons/Button'

import Invites from '@/services/Investor/Invites/Invites'

export default {
  name: 'invite-friends-modal',
  components: { VInput, VButton },
  data () {
    return {
      loading: false,
      email: '',
      emails: []
    }
  },
  methods: {
    async addEmail () {
      if (await this.$validator.validateAll()) {
        this.emails.push(this.email)
        this.email = ''
        this.$validator.reset()
      }
    },
    async submit () {
      try {
        this.loading = true
        const response = (await Invites.sendInvites(this.emails)).data
        if (response.code === 200) {
          this.$store.dispatch('notification', { type: 'success', message: response.mensagem })
        } else {
          const erros = Object.values(response.erros)
          if (erros.length === 1) {
            this.$store.dispatch('notification', { type: 'error', message: erros[0] })
          }
        }
      } catch (error) {
        this.$store.dispatch('notification', { type: 'error', message: 'Houve algum erro ao convidar amigos. Tente novamente.' })
        console.error(error)
      } finally {
        this.$emit('closeModal')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full h-full overflow-y-auto bg-white z-50 flex flex-col rounded-lg p-4
  min-height: 15rem
  @screen lg
    @apply w-3/4

.title
  @apply text-secondary font-bold mb-4 text-center mt-2
  @screen lg
    @apply text-left text-lg

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

table
  @apply w-full border-none mt-4
  th
    @apply py-1 opacity-75 font-bold text-sm text-left bg-gray-200 px-6
  tr
    @apply py-2 border-t border-b border-gray-200
    &:hover
      @apply bg-gray-100
  td
    @apply py-1 opacity-75 text-sm self-start

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>
