import api from '@/plugins/axios'

const base = 'v1/investidor/'

export default {
  getInvites () {
    return api.get(`${base}indicacoes`)
  },
  sendInvites (data) {
    return api.post(`${base}indicacoes`, data)
  }
}
