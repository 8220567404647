<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <p class="title">Convites aceitos</p>
        <div class="w-full overflow-x-auto lg:overflow-x-hidden">
          <vuetable ref="vuetable"
            class="invites_table"
            :fields="header"
            :api-mode="false"
            :data-manager="dataManager"
            :no-data-template="loading ? '' : 'Nenhum dado retornado'"
          >
            <template v-slot:data="props">
              {{$moment(props.rowData.dataCadastro).format('DD/MM/YYYY')}}
            </template>
            <template v-slot:email="props">
              <p class="text-left px-2">{{props.rowData.email}}</p>
            </template>
          </vuetable>
        </div>
        <div v-if="loading" class="h-full w-full flex justify-center items-center">
          <i class="fas fa-spinner fa-spin text-2xl text-secondary"></i>
        </div>
        <div class="flex justify-end">
          <vuetable-pagination
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
            :css="paginationCss"
          ></vuetable-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VuetablePagination } from 'vuetable-2'

import Invites from '@/services/Investor/Invites/Invites'

export default {
  name: 'modal-friends',
  components: { VuetablePagination },
  data () {
    return {
      loading: true,
      invites: [],
      pagination: {
        current_page: 0,
        last_page: 0,
        per_page: 15,
        total: 0
      },
      header: [
        {
          name: '__slot:data',
          title: 'Data Cadastro',
          width: '20%'
        },
        {
          name: '__slot:email',
          title: 'E-mail',
          width: '60%'
        },
        {
          name: 'fezPrimeiroInvestimento',
          title: 'Fez Primeiro Investimento?',
          width: '20%'
        }
      ],
      paginationCss: {
        wrapperClass: 'border rounded-lg flex m-2 text-secondary',
        activeClass: 'text-primary shadow-inner bg-gray-100 font-bold',
        disabledClass: 'text-gray-300 cursor-not-allowed',
        pageClass: 'pagination_page_class',
        linkClass: 'pagination_page_class',
        icons: {
          first: 'fas fa-angle-double-left',
          prev: 'fas fa-angle-left',
          next: 'fas fa-angle-right',
          last: 'fas fa-angle-double-right'
        }
      }
    }
  },
  methods: {
    onChangePage (page) {
      let reload = false
      switch (true) {
        case page === 'next':
          if (!this.$refs.pagination.isOnLastPage) {
            this.pagination.current_page = this.pagination.current_page + 1
            reload = true
          }
          break
        case page === 'prev':
          if (!this.$refs.pagination.isOnFirstPage) {
            this.pagination.current_page = this.pagination.current_page - 1
            reload = true
          }
          break
        default:
          if (this.pagination.current_page !== page) {
            this.pagination.current_page = page
            reload = true
          }
          break
      }
      if (reload) {
        this.$refs.vuetable.reload()
      }
    },
    dataManager (sortOrder, pagination) {
      if (this.invites.length < 1) return

      const from = (this.pagination.current_page - 1) * this.pagination.per_page
      const to = from + this.pagination.per_page

      return {
        pagination: this.pagination,
        data: this.invites.slice(from, to)
      }
    }
  },
  async mounted () {
    try {
      this.loading = true
      this.invites = (await Invites.getInvites()).data.data
      this.pagination = {
        current_page: 1,
        last_page: Math.ceil(this.invites.length / this.pagination.per_page),
        total: this.invites.length,
        per_page: this.pagination.per_page
      }
      this.$refs.vuetable.reload()
      this.$refs.pagination.setPaginationData(this.pagination)
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="sass" scoped>
.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full h-full overflow-y-auto bg-white z-50 flex flex-col rounded-lg p-4
  min-height: 15rem
  @screen lg
    @apply w-3/4

.title
  @apply text-secondary font-bold mb-4 text-center mt-2
  @screen lg
    @apply text-left text-lg

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>

<style lang="sass">
.invites_table
  @apply w-full bg-white shadow rounded-lg
  thead tr
    @apply rounded-t-lg
  tbody
    @apply rounded-b-lg
  tr
    &:nth-child(even)
      @apply bg-gray-100
    &:last-child
      @apply rounded-b-lg
      td:first-child
        @apply rounded-bl-lg
      td:last-child
        @apply rounded-br-lg
    th
      @apply p-2 text-sm text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-sm border border-gray-300 py-1 text-center

.pagination_page_class
  @apply h-8 w-8 border-l flex justify-center items-center cursor-pointer
  &:first-of-type
    @apply border-l-0 rounded-l-lg
  &:last-of-type
    @apply rounded-r-lg
  &:hover
    @apply bg-gray-100
    &.cursor-not-allowed
      @apply bg-transparent
</style>
