<template>
  <div>
    <div v-if="dateRoundInvestment && dateRoundInvestment.isAfter(new Date())" class="countdown">
      <p class="title">Próxima Rodada de Investimentos</p>
      <p class="subtitle">{{dateRoundInvestment | moment('DD/MM/YYYY')}} - {{dateRoundInvestment | moment('HH:mm')}}h</p>
      <div class="content">
        <div class="countdown-number" v-if="round.days > 0">
          <div class="digits">
            <span class="digit mr-1">{{round.days | first_letter}}</span>
            <span class="digit ml-1">{{round.days | last_letter}}</span>
          </div>
          <span class="legend">Dias</span>
        </div>
        <span class="text-white text-2xl mx-2" v-if="round.days > 0">:</span>
        <div class="countdown-number">
          <div class="digits">
            <span class="digit mr-1">{{round.hours | first_letter}}</span>
            <span class="digit ml-1">{{round.hours | last_letter}}</span>
          </div>
          <span class="legend">Horas</span>
        </div>
        <span class="text-white text-2xl mx-2">:</span>
        <div class="countdown-number">
          <div class="digits">
            <span class="digit mr-1">{{round.minutes | first_letter}}</span>
            <span class="digit ml-1">{{round.minutes | last_letter}}</span>
          </div>
          <span class="legend">Minutos</span>
        </div>
        <span class="text-white text-2xl mx-2" v-if="round.days === 0">:</span>
        <div class="countdown-number" v-if="round.days === 0">
          <div class="digits">
            <span class="digit mr-1">{{round.seconds | first_letter}}</span>
            <span class="digit ml-1">{{round.seconds | last_letter}}</span>
          </div>
          <span class="legend">Segundos</span>
        </div>
      </div>
    </div>
    <div v-else class="countdown_open">
      <img class="h-20" :src="require('@/assets/images/investor_panel/open_round.svg')">
      <p class="text-white text-xl text-center px-2 font-bold">A rodada atual de investimentos está aberta!</p>
    </div>
  </div>
</template>

<script>
import Dashboard from '@/services/Investor/Dashboard'

export default {
  name: 'invetiment-round',
  data () {
    return {
      dateNow: this.$moment(),
      dateRoundInvestment: null,
      dateStart: '',
      diffTime: '',
      duration: '',
      round: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }
  },
  async created () {
    try {
      const response = (await Dashboard.getNextInvestimentRound()).data.data
      if (!response) return
      // começa aqui
      this.dateRoundInvestment = this.$moment(response)
      this.diffTime = this.dateRoundInvestment.diff(this.dateNow)
      this.duration = this.$moment.duration(this.diffTime, 'milliseconds', true)
      this.round = {
        days: this.duration.days(),
        hours: this.duration.hours(),
        minutes: this.duration.minutes(),
        seconds: this.duration.seconds()
      }
      if (this.round.days > 0) {
        this.interval = setInterval(() => {
          this.duration = this.$moment.duration(this.duration - (1000 * 60), 'milliseconds')
          this.round = {
            days: this.duration.days(),
            hours: this.duration.hours(),
            minutes: this.duration.minutes(),
            seconds: this.duration.seconds()
          }
        }, 1000 * 60)
      } else {
        this.interval = setInterval(() => {
          this.duration = this.$moment.duration(this.duration - 1000, 'milliseconds')
          this.round = {
            days: this.duration.days(),
            hours: this.duration.hours(),
            minutes: this.duration.minutes(),
            seconds: this.duration.seconds()
          }
        }, 1000)
      }
    } catch (error) {
      console.error(error)
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  filters: {
    first_letter (value) {
      if (value.toString().length <= 1) {
        return `0${value.toString()}`[0]
      }
      return value.toString()[0]
    },
    last_letter (value) {
      if (value.toString().length <= 1) {
        return `0${value.toString()}`[1]
      }
      return value.toString()[1]
    }
  }
}
</script>

<style lang="sass" scoped>
.countdown
  @apply w-full h-48 shadow bg-primary rounded-lg shadow flex flex-col justify-center items-center
  &_open
    @apply w-full h-48 shadow bg-secondary rounded-lg shadow flex flex-col justify-center items-center

.title
  @apply text-center text-white text-base

.subtitle
  @apply text-center text-white text-xl

.content
  @apply flex justify-center mt-3

.countdown-number
  @apply flex flex-col items-center

.digits
  @apply flex justify-center

.digit
  @apply bg-white text-gray-700 font-extrabold text-2xl w-6 text-center
  border-radius: 5px

.legend
  @apply text-white text-xs opacity-75 mt-1 uppercase

</style>
